<template>
  <div>
    <b-row class="" align-h="center">
      <b-col cols="12">
        <Card class="py-4 px-4 login-card">
          <template v-slot:content>
            <!--begin::Signin-->
            <div class='login-form login-signin'>
              <div class='text-center'>
                <b-iconstack font-scale="5" class="mb-5" >
                  <b-icon stacked icon="shield-fill" variant="success" scale="1"></b-icon>
                  <b-icon stacked icon="check" variant="white"></b-icon>
                </b-iconstack>

                <h4>{{ $t('GENERAL.congratulations') }}</h4>

                <p class="paragraph">{{ $t('AUTH.forgot_password_success') }}</p>

                <router-link :to="{ name: 'login' }" class="btn btn-primary mt-3">{{ $t('GENERAL.back_to_home') }}</router-link>
              </div>
            </div>
            <!--end::Signin-->
          </template>
        </Card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'ForgotPasswordSuccess'
}
</script>

<style lang='scss' scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important
}

.resend-link {
  margin-top: 1rem;
  display: block;
  color: $primary-dark;

  cursor: pointer;
}
</style>
